<template>
  <Header />

  <div class="orm-container" v-if="station">
    <div class="orm-row" v-if="apiKey">
      <div class="orm-col-xs-12">
        <div class="borderRadiusTBRL overflowHidden">
          <gMap
            :mapConfig="mapConfig"
            :apiKey="apiKey"
            v-if="station.latitude"
            v-slot="{ google, map, bounds }"
          >
            <gMapMarker
              v-if="station.latitude != 0"
              :marker="station"
              :google="google"
              :map="map"
              :bounds="bounds"
              :key="station"
              :noselect="true"
            />
          </gMap>
        </div>
      </div>
    </div>
    <div class="orm-row orm-my-64 center-xs">
      <div class="orm-col-xs-12">
        <h1 class="orm-headline__h1">{{ station.name }}</h1>
        <div v-if="station.street">{{ station.street }}</div>
        <div v-if="station.zipcode">
          {{ station.zipcode }} {{ station.city }}
        </div>
        <div v-if="station.phone" class="orm-mt-16">
          <i class="fas fa-phone"></i> {{ station.phone }}
        </div>
        <div v-if="station.email">
          <i class="fas fa-envelope"></i> {{ station.email }}
        </div>
        <div v-if="station.opening_date">{{ station.opening_date }}</div>
      </div>
    </div>
    <div class="orm-row orm-mb-64">
      <div
        class="orm-background--greytowhite orm-col-xs-12 orm-col-md-12 orm-py-30 orm-px-30"
      >
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-col-md-12 orm-col-lg-3">
            <div class="orm-row">
              <div class="orm-col-xs-12">
                <div class="orm-searchbar__headline">
                  <strong>{{ i18n("general/station") }}</strong>
                </div>
              </div>
              <stationpicker
                class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                :data="stations"
                type="start"
              />
              <stationpicker
                class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                :data="stations"
                type="end"
              />
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-lg-7" style="position:relative">
            <datepicker />
          </div>
          <div
            class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-2 orm-col-sm-offset-6 orm-col-lg-offset-0 text-right"
          >
            <div class="orm-searchbar__headline">
              <strong>&nbsp;</strong>
            </div>
            <button
              @click="getCars('/reservation/step1')"
              class="orm-btn orm-btn--large orm-btn--searchbar"
              :class="[station ? '' : 'disable']"
            >
              {{ i18n("general/showOffers") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>
<script>
import { mapSettings } from "@/constants/mapSettings";
import store from "@/store";
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src

import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";

export default {
  components: { Footer, Header, datepicker, stationpicker, gMap, gMapMarker },
  props: ["name"],
  setup() {
    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };
    return {
      mapConfig,
    };
  },
  data() {
    return {
      stations: store.getters["stations/GET_STATIONS"],
      station: Object,
      merchantId: process.env.VUE_APP_CONCARDIS_MERCHANT_ID,
      apiKey: null,
      bounds: [],
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  async created() {
    const clientdata = await store.getters["general/GET_CLIENT_DATA"];
    this.apiKey = clientdata["maps_apikey"];
    //   GET_STATION_BY_NAME
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      async () => {
        if (this.$route.params.name) {
          this.station = {};
          this.station = await store.getters["stations/GET_STATION_BY_SLUG"](
            this.$route.params.name
          );
          this.bounds = [];
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  methods: {
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        store.dispatch("stations/SET_OPENINGTIMES").then(() => {
          if (process.env.VUE_APP_STANDALONE == "true") {
            this.$router.push({ name: "Reservation_Step1" });
          } else {
            window.location.href =
              process.env.VUE_APP_BASE_URL +
              process.env.VUE_APP_RESERVATION_PATH +
              process.env.VUE_APP_RESERVATION_PATH_STEP1;
          }
        });
      }
    },
  },
};
</script>
